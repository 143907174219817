import React, { useState, useEffect, useRef } from "react";
import DiagramRegistryList from "./DiagramRegistryList";
import diagramRegistry from "@hugo_volare/diagrams-layout-lib";
import CodeEditor from "./CodeEditor";
import styled from "styled-components";
import { Title } from "../Home";
import { Link } from "@reach/router";
import { saveAsSVG } from "./exportDiagram";
import SplitPane from "react-split";
import "./SplitPane.css";
import twitterlogo from "./Twitter_Logo_Blue.svg";
import ShareableUrlInput from "./ShareableUrlInput";
import defaultTheme from "./defaultTheme";
import DiagramPreview from "./DiagramPreview";
import icon from "../icons/icon.png";
import {
  PageHeader,
  Typography,
  Button,
  Layout,
  Dropdown,
  Menu,
  Space,
  Modal,
  Popover,
  Link as ButtonLink
} from "antd";
import templates from "../templates";
import {
  DownOutlined,
  UserOutlined,
  ShareAltOutlined,
  CloudUploadOutlined,
  CloudOutlined,
  DesktopOutlined,
} from "@ant-design/icons";

const { Paragraph, Text } = Typography;

const { Header, Footer, Sider, Content } = Layout;

const StyledHeader = styled(PageHeader)`
  border-bottom: solid 1px grey;
`;

const StudioBanner = () => {
  return (
    <div className="ib">
      <a
        className="flex flex-row"
        href="https://studio.diagram.codes"
        target="_blank"
      >
        <img src={icon} style={{ width: "40px", height: "40px" }} />
        <div>
          <span className="b db">
            Diagram.Codes Studio for Mac OS and Windows{" "}
          </span>
          <span className="db f7">
            Offline support, color themes, multiple export options and more.
          </span>
        </div>
      </a>
    </div>
  );
};

const CloudBanner = () => {
  return (
    <div className="ib">
      <a
        className="flex flex-row"
        href="https://cloud.diagram.codes"
        target="_blank"
      >
        <Space>
          <CloudOutlined style={{ fontSize: "40px" }} />
          <div>
            <span className="b db">
              Diagram.codes Cloud Available (<Text success>*New</Text>)
            </span>
            <span className="db f7">
              Access your diagrams from anywhere and collaborate with your Team.
            </span>
          </div>
        </Space>
      </a>
    </div>
  );
};

const ContentLayout = (props) => {
  return <div className="w-100 h-100">{props.children}</div>;
};

const HorizontalLayout = (props) => {
  return <div className="flex flex-row  w-100 h-100">{props.children}</div>;
};

const CloudLink = styled.a `
  
`

const DesktopLink = styled.a `
  
`

const TwitterLink = () => {
  return (
    <a
      className="link b ml2 dib flex items-center"
      href="https://twitter.com/DiagramCodes"
    >
      <img
        style={{ width: "20px", height: "20px" }}
        alt="twitter logo"
        src={twitterlogo}
      />
      <span> @DiagramCodes </span>
    </a>
  );
};
const TopBar = (props) => (
  <StyledHeader
    title=<Link to="/">Diagram.codes</Link>
    subTitle="Online editor"
    extra={[
      <Popover
        content={<CloudBanner key="cloudbanner" />}
        title="Cloud"
        trigger="hover"
      >
        <CloudLink href="https://cloud.diagram.codes" target="_blank"><CloudOutlined/> Cloud for Teams</CloudLink>
      </Popover>,
      <Popover
        content={<StudioBanner key="studiobanner" />}
        title="Desktop (Mac/Windows)"
        trigger="hover"
      >
        <DesktopLink href="https://studio.diagram.codes" target="_blank"><DesktopOutlined/> Desktop Client</DesktopLink>
      </Popover>,
    ]}
  />
);
const EditorContainer = (props) => (
  <div className="vh-100 flex flex-column bg-white">{props.children}</div>
);
const CodeEditorLayout = (props) => (
  <div className="h-100 flex flex-column">
    <div className="w-100 h-100">
      <div className="w-100 h-100 border-box relative">
        <CodeEditor
          diagramType={props.diagramType}
          value={props.input || ""}
          onChange={props.onChange}
          className="absolute top-0 bottom-0 right-0 left-0"
        />
      </div>
    </div>
  </div>
);

const EditorToolbar = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 4px 0;
  justify-content: center;
  background: #f2f1f1;
`;

const DiagramRenderLayout = ({ diagramType, input }) => {
  return (
    <div className="h-100 pb5 flex flex-column">
      <div className="self-stretch bg-white h-100 flex  justify-center">
        <DiagramPreviewContainer input={input} type={diagramType} />
      </div>
    </div>
  );
};

/* Este componente configura el parser dependiendo del tipo de diagrama
   y obtiene el objeto layout que se le pasa al SVG Renderer */
const DiagramPreviewContainer = ({ input, type }) => {
  let module = diagramRegistry.getModule(type);
  let model = new module.Model();
  if(process.env.REACT_APP_DEMO_MODE){
    model.demoMode = true
    console.log('demoMode set to true')
  }
  let parser = module.parser;
  parser.yy.model = model;

  let [lastGoodLayout, setLastGoodLayout] = useState(null);
  let [parserError, setParserError] = useState(null);

  useEffect(() => {
    try {
      parser.parse(input);
      let layout = module.getLayout(model, defaultTheme);
      setLastGoodLayout(layout);

      //Only save non empty diagrams
      if (input.trim().length > 0) {
        saveLastGoodLayout(type, input);
      }
    } catch (e) {
      console.error(e);
    } finally {
      parser.cleanupAfterParse();
    }
  }, [input]);

  if (lastGoodLayout == null) return null;

  return (
    <DiagramPreview
      diagramLayout={lastGoodLayout}
      zoomFactor={1.1}
      theme={defaultTheme}
    />
  );
};

const StatusBar = (props) => (
  <div
    style={{ flexShrink: 0 }}
    className="bg-light-gray black-80 ph3 h2 flex w-100 self-end items-center justify-between z-1"
  >
    <span className="justify-start">
      <Space>
        <a className="link black-80 tu" href="https://volarelabs.co">
          ® 2020 - Volare Labs Inc.
        </a>

        <a className="link black-80 tu" href="https://cloud.diagram.codes">
          Cloud Platform
        </a>

        <a className="link black-80 tu" href="https://studio.diagram.codes">
          Desktop Client
        </a>
        <a className="link black-80 tu" href="https://oss-attribution.diagram.codes/attribution.txt">
          OSS Attributions
        </a>
      </Space>
    </span>
    <a className="link b ml2" href="https://contact.diagram.codes">
      Contact Us.
    </a>
    <a className="link black-80 align-end" href="/termsofuse.html">
      Terms of Use
    </a>
  </div>
);

export default ({ diagramType }) => {
  const [input, setInput] = useState("");
  const [sharedDiagramCode, setSharedDiagramCode] = useState("");
  const debouncedInput = useDebounce(input, 500);
  const [promptUseLastVisible, showPromptUseLast] = useState(false);
  const [lastGoodLayout, setLastGoodLayout] = useState(null);
  const [diagramTypeStarterTemplate, setDiagramTypeStarterTemplate] = useState(
    ""
  );

  //Nota: se desactivó sharing para la version gratis
  //sin embargo seguimos soportando shared urls para los diagramas
  //que se alcanzaron a compartir con ese feature
  useEffect(() => {
    if (!diagramType) return;

    const starterTemplate = templates[diagramType];
    setDiagramTypeStarterTemplate(starterTemplate);

    //Revisar si la url tiene código de un diagrama shared
    //Viene así: host/d/tree/codigo-aqui.
    const urlparts = window.location.pathname.split("/");
    let sharedDiagramCode; //
    if (urlparts.length >= 4 && urlparts[1] === "d") {
      sharedDiagramCode = window.decodeURIComponent(urlparts[3]);
      setInput(sharedDiagramCode);
    } else {
      //No es shared url, revisar si hay diagrama guardado
      const last = getStoredLastGoodLayout(diagramType);
      setLastGoodLayout(last);
      if (last && last != starterTemplate) {
        //Si hay lastGoodLayout preguntarle al usuario
        showPromptUseLast(true);
      } else {
        //Si no hay usamos el template
        setInput(starterTemplate);
      }
    }
  }, [diagramType]);

  const handleUseLastDoc = () => {
    showPromptUseLast(false);
    setInput(lastGoodLayout);
  };

  const handleUseStarterTemplate = () => {
    showPromptUseLast(false);
    setInput(diagramTypeStarterTemplate);
  };

  const shareMenu = (
    <Menu>
      <Menu.Item>
        <Space>
          <a href="https://cloud.diagram.codes" target="_blank">
            Get Shareable Image URL
          </a>
          <Text mark>Pro feature</Text>
        </Space>
      </Menu.Item>
      <Menu.Item>
        <Space>
          <a href="https://cloud.diagram.codes" target="_blank">
            Embed Diagram in Website
          </a>
          <Text mark>Pro feature</Text>
        </Space>
      </Menu.Item>
      <Menu.Item>
        <Button
          onClick={() => {
            saveAsSVG("#mainsvg");
          }}
        >
          Download SVG
        </Button>
      </Menu.Item>
    </Menu>
  );

  const saveMenu = (
    <Menu>
      <Menu.Item>
        <Space>
          <a href="https://cloud.diagram.codes" target="_blank">
            Create New Project
          </a>
          <Text mark>Pro feature</Text>
        </Space>
      </Menu.Item>
      <Menu.Item>
        <Space>
          <a href="https://cloud.diagram.codes" target="_blank">
            Save Diagram
          </a>
          <Text mark>Pro feature</Text>
        </Space>
      </Menu.Item>
    </Menu>
  );

  const teamMenu = (
    <Menu>
      <Menu.Item>
        <Space>
          <a href="https://cloud.diagram.codes" target="_blank">
            Add Team Membr
          </a>
          <Text mark>Pro feature</Text>
        </Space>
      </Menu.Item>
    </Menu>
  );

  const ModalUseTemplate = (
    <Modal
      title="Continue editing diagram?"
      visible={promptUseLastVisible}
      footer={null}
    >
      <Space direction="vertical">
        <Button onClick={handleUseLastDoc}>
          {" "}
          Continue editing previously opened document{" "}
        </Button>
        <Button type="primary" onClick={handleUseStarterTemplate}>
          <p>
            Start <b>New</b> from template
          </p>
        </Button>
      </Space>
    </Modal>
  );

  return (
    <EditorContainer>
      <TopBar />
      {ModalUseTemplate}
      <EditorToolbar center>
        <Space>
          <Dropdown overlay={saveMenu}>
            <Button onClick={(e) => e.preventDefault()}>
              <CloudUploadOutlined />
              Save <DownOutlined />
            </Button>
          </Dropdown>
          {/*<ShareableUrlInput diagramType={diagramType} diagramCode={input} />*/}
          <Dropdown overlay={shareMenu}>
            <Button onClick={(e) => e.preventDefault()}>
              <ShareAltOutlined /> Share Diagram <DownOutlined />
            </Button>
          </Dropdown>
        </Space>
      </EditorToolbar>
      <ContentLayout>
        <SplitPane
          className="h-100 flex"
          sizes={[50, 50]}
          minSize={100}
          expandToMin={false}
          gutterSize={10}
          gutterAlign="center"
          snapOffset={30}
          dragInterval={1}
          direction="horizontal"
          cursor="col-resize"
        >
          <div>
            <CodeEditorLayout
              diagramType={diagramType}
              input={input}
              onChange={(val) => {
                setInput(val);
              }}
            />
          </div>
          <DiagramRenderLayout
            diagramType={diagramType}
            input={debouncedInput}
          />
        </SplitPane>
      </ContentLayout>
      <StatusBar />
    </EditorContainer>
  );
};

let storage = window.localStorage;

/* Funciones para guardar el ultimo diagrama bueno en localstorage
y evitar perderlo si el usuario se sale por error */
function saveLastGoodLayout(type, val) {
  storage.setItem(type, val);
}
function getStoredLastGoodLayout(type) {
  let item = storage.getItem(type);
  return item;
}

//TODO: move to file
function useDebounce(value, delay) {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler);
      };
    },
    [value, delay] // Only re-call effect if value or delay changes
  );

  return debouncedValue;
}
