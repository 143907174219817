var mindmap = require('./diagrams/mind-map');

var stack = require('./diagrams/stack');

var systemLayers = require('./diagrams/system-layers');

var tree = require('./diagrams/tree');

var flowchart = require('./diagrams/flowchart');

var timeline = require('./diagrams/timeline');

var graph = require('./diagrams/graph');

var statemachine = require('./diagrams/state-machine');

var entityrelationship = require('./diagrams/entity-relationship');

var classdiag = require('./diagrams/class_diag');

var dataflow = require('./diagrams/dataflow'); // Nota: La llave debe ser el id del tipo de diagrama.


module.exports = {
  'mind-map': mindmap,
  'stack': stack,
  'system-layers': systemLayers,
  'tree': tree,
  'tree-left-to-right': tree,
  'onion-layers': tree,
  'sequence': tree,
  'flowchart': flowchart,
  'timeline': timeline,
  'graph': graph,
  'state-machine': statemachine,
  'entity-relationship': entityrelationship,
  'class_diag': classdiag,
  'dataflow': dataflow
};