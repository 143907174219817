module.exports = {
  start: [{
    token: "comment",
    regex: "#.*$"
  }, {
    token: "string",
    regex: '".*?"'
  }, {
    token: "keyword",
    regex: /IF|if/
  }, {
    token: "keyword",
    regex: /ELSE|else/
  }, {
    token: "keyword",
    regex: "end if|END IF"
  }, {
    //multiline comments
    token: 'string',
    regex: '"',
    caseInsensitive: true,
    push: "stringcontinuation"
  }],
  //multiline comments
  stringcontinuation: [{
    token: 'string',
    regex: '"',
    caseInsensitive: true,
    next: "pop"
  }, {
    defaultToken: "string"
  }]
};