/* DiagramPreview
   Componente que renderiza un objeto de tipo DiagramLayout
*/
import React, { useState, useEffect } from "react";
import diagramRegistry from "@hugo_volare/diagrams-layout-lib";
import { SVGRenderer } from "@hugo_volare/diagrams-svg-renderer";
import styled from "styled-components";

const defaultOptions = {
  zoomFactor: 1.1
};

const PreviewBackground = styled.div`
  background: #fff;
  width: 100%;
  height: 100%;
  overflow: auto;
  position: relative;
`;

const Document = styled.div`
  background-color: ${props => props.fill};
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px;
`;

const DiagramPreview = ({
  diagramLayout,
  zoomFactor = defaultOptions.zoomFactor,
  theme
}) => {
  if (diagramLayout == null) return null;
  if (theme == null) return null;

  console.log('The theme:', theme);


  const docPadding = 80;
  const docWidth = `${diagramLayout.bbox.width * zoomFactor + docPadding}px`;
  const docHeight = `${diagramLayout.bbox.height * zoomFactor + docPadding}px`;

  return (
    <PreviewBackground >
      <Document fill={theme.document.backgroundColor} style={{ width: docWidth, height: docHeight }}>
        <SVGRenderer zoom={zoomFactor} layout={diagramLayout} theme={theme} />
      </Document>
    </PreviewBackground>
  );
};

export default DiagramPreview;
