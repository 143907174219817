import flowchart from './flowchart.svg';
import systemLayers from './system-layers.svg';
import layerStack from './layer-stack.svg';
import onionLayers from './onion-layers.svg';
import sequence from './sequence.svg';
import graph from './graph.svg';
import timeline from './timeline.svg';
import tree from './tree.svg';
import treeHorizontal from './tree-horizontal.svg';
import mindMap from './mind-map.svg';
import stateMachine from './state-machine.svg';
import entityRelationship from './entity-relationship.svg';
import classDiagram from './class_diag.svg';
import dataFlow from './dataflow.svg';


export default {
  ['flowchart']: flowchart,
  ['system-layers']:systemLayers,
  ['stack']: layerStack,
  ['onion-layers']: onionLayers,
  ['sequence'] : sequence,
  ['graph']: graph,
  ['timeline'] : timeline,
  ['tree'] : tree,
  ['tree-left-to-right']: treeHorizontal,
  ['mind-map']: mindMap,
  ['state-machine']: stateMachine,
  ['entity-relationship']: entityRelationship,
  ['class_diag']: classDiagram,
  ['dataflow']: dataFlow,
}
