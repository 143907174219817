module.exports = {
  start: [{
    token: "comment",
    regex: "#.*$"
  }, {
    token: "string",
    regex: '".*?"'
  }, {
    token: "keyword",
    regex: "alias"
  }, {
    token: "keyword",
    regex: "->"
  }, {
    token: "keyword",
    regex: "=>"
  }, {
    token: "keyword",
    regex: "="
  }, {
    //multiline comments
    token: 'string',
    regex: '"',
    caseInsensitive: true,
    push: "stringcontinuation"
  }],
  //multiline comments
  stringcontinuation: [{
    token: 'string',
    regex: '"',
    caseInsensitive: true,
    next: "pop"
  }, {
    defaultToken: "string"
  }]
};