export default {
    id:'default',
    name: 'Default',
    document: {
        backgroundColor: "#fff",
        shadowColor: "black",
        shadow: false,

    },
    /* Estilos que aplican a nodos */
    nodes: {
        fontFamily: "Arial",
        fontSize: "16",
        textColor:'black',
        fillColor: "white",
        borderWidth: "2",
        borderColor: "black",
        borderRadius: 3,
        padding: 8,
    },
    /* Estilos que aplican a texto suelto */
    labels: {
        fontFamily: "Arial",
        fontSize: "14",
        textColor:'black',
        fillColor: "white",
        borderWidth: "0",
        borderColor: "none",
        borderRadius: 0,
        padding: 4,
    },
    connectors: {
        color: "black",
        width: "2",
    }
};
