export default `
alias john="John"
alias mari="Mari "
alias hugo="Hugo"
alias Zek="Zek"

Zek->john:"hey"
john->mari: "hello"
mari=>john: "hey! this is bold! 👋"
john-->mari: "Here's a dashed line!"
mari->mari: "A message to myself 🦋"
john->hugo: "hello hugo"
hugo->john: "hey!, how's it going?"
mari=>john: "hey! this is bold! 👋"

`