/* 
Función que crea un customEditor a partir de unas reglas
especificas para cada diagrama (ver modulo editor-syntax-rules)

Links:

https://cloud9-sdk.readme.io/docs/highlighting-rules

referencia tomada de https://github.com/securingsincity/react-ace/issues/126#issuecomment-345151567
Lista de common tokens:

https://github.com/ajaxorg/ace/wiki/Creating-or-Extending-an-Edit-Mode#extendingTheHighlighter

Ejemplo definición de tokens:
https://github.com/thlorenz/brace/blob/master/mode/clojure.js


*/
import AceEditor from "react-ace";
import "brace";
import "brace/mode/text";


const createCustomEditor = (customRules = []) => {
	//Crear la clase contenedora de las reglas
	class CustomHighlightRules extends window.ace.acequire(
		"ace/mode/text_highlight_rules"
	).TextHighlightRules {
		

		constructor() {
			super();
			this.$rules = customRules;
			this.normalizeRules();
		}

	}
	//Crear el custom mode editor basado en esa clase
   class CustomEditorMode extends window.ace.acequire("ace/mode/text")
		.Mode {
		constructor() {
			super();
			this.HighlightRules = CustomHighlightRules;
		}
	};

	return new CustomEditorMode();
};

export default createCustomEditor;
