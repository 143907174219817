import React from 'react';
import logo from './logo.svg';
import Home from './Home';
import PlaygroundHome from './PlaygroundHome'
import Editor from './editor';
import ApiRenderer from './api/ApiRenderer';
import '../node_modules/tachyons/css/tachyons.css';
import './App.css';
import {Router, Link} from '@reach/router';

function App() {
  return (
    <Router>
      <Home path="/"/>
      <Editor path="/d/:diagramType"/>
      <Editor path="/d/:diagramType/:diagramCode"/>
      <ApiRenderer path="/apirender"/>
    </Router>
  );
}

export default App;
