/* Exportar SVG a imagen y como SVG */

function saveAsPNG(selector, name = "diagram.png") {
	//TODO:...
	//No funciona bien las tecnicas encontradas en SO
	//problemas en safari, ie, se basa en canvg que no es la implementacion
	//completa
}

function saveAsSVG(selector, name="diagram.svg") {
	let svg = document.querySelector(selector);
	if (!svg) {
		throw new Error("SVG not found for selector:", selector);
	}
	let data = new XMLSerializer().serializeToString(svg);
	let DOMURL = window.URL || window.webkitURL || window;
	let img = new Image();
	let svgBlob = new Blob([data], { type: "image/svg+xml;charset=utf-8" });
	let url = DOMURL.createObjectURL(svgBlob);

	let a = document.createElement("a");
	a.setAttribute("download", name);
	a.setAttribute("href", url);
	a.setAttribute("target", "_blank");
	a.dispatchEvent(
		new MouseEvent("click", {
			view: window,
			bublles: false,
			cancelable: true
		})
	);
}

export { saveAsPNG, saveAsSVG };
