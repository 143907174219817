import React from "react";
import { Link } from "@reach/router";
import { List, Avatar, Card } from "antd";
import registry from "@hugo_volare/diagrams-layout-lib";
import styled from "styled-components";
import FeedbackButton from "./feedback/SendFeedbackButton";
import icons from "./icons";
import StudioBannerImage from "./studio-banner.png";
const { Meta } = Card;

const DiagramImage = styled.img`
  width: 100px;
  height: auto;
  padding: 20px;
`;

const Quote = (props) => <div className="f2">{props.children}</div>;
const Title = (props) => <h1 className="f4 f2-ns">{props.children}</h1>;
const QuoteDesc = (props) => (
  <div className="p2 mv3 measure-wide">{props.children}</div>
);
const DiagramCard = (props) => (
  <Link to={props.d.path} className="link dib flex bb bw2 black-80">
    <Card
      hoverable
      style={{ width: 240 }}
      cover={
        <DiagramImage alt={props.d.name + " thumbnail"} src={props.d.img} />
      }
    >
      <Meta title={props.d.name} description={props.d.desc} />
    </Card>
  </Link>
);

const StatusBar = (props) => (
  <div className="bg-black-10 black-80 ph3 mt6 flex flex-column flex-row-ns w-100 self-end items-center justify-between z-1 mt4">
    <span className="justify-start">
      ® 2020 -{" "}
      <a className="link black tu" href="https://volarelabs.co">
        Volare Labs
      </a>
    </span>
    <a className="link b ml2" href="https://contact.diagram.codes">
      Support
    </a>
    <a className="link black align-end" href="/termsofuse.html">
      Terms of Use
    </a>
  </div>
);

/*
 <ul className="cf pa2 list">
    {props.diagrams.map((d, ix) => (
      <li className="fl w-100  pa2" key={ix}>
        <DiagramCard key={ix} d={d} />
      </li>
    ))}
    <li className="fl w-100  pa2 tc pv5" key={"more"}>
      <span>
        More diagrams coming soon. Follow us on Twitter for updates
        <a className="link b ml2" href="https://twitter.com/DiagramCodes">
          @DiagramCodes.
        </a>
      </span>
    </li>
  </ul>
*/

const newDiagrams = ["dataflow"]

const NewDiagram = styled.span`
  padding: 0.2rem;
  background-color: yellow;
  font-size: 0.7rem;
`

const DiagramList = (props) => (
  <List
    itemLayout="horizontal"
    dataSource={props.diagrams}
    renderItem={(item) => (
      <List.Item>
        <List.Item.Meta
          avatar={<Avatar src={item.img} />}
          title={<a href={item.path}>{item.name} {newDiagrams.includes(item.id) ? <NewDiagram>New!</NewDiagram> : null}</a>}
          description={item.desc}
        />
      </List.Item>
    )}
  />
);

const Intro = (props) => (
  <div className="fl w-100  w-40-ns bg-white h-100-ns ">
    <div className="pa3 ph5-ns measure mw5">
      <Title>Diagram.codes</Title>
      <QuoteDesc>
        This is the playground for the Diagram.Codes Platform.
        Here, you can generate diagrams from simple text conventions.
      </QuoteDesc>

      <h2>Solutions for Teams (Cloud)</h2>
      <QuoteDesc>
         <a href="https://cloud.diagram.codes"> cloud.diagram.codes </a>
        provides teams with easy to use text-to-diagram features.
        
        For everyday communication, planning and documentation activities.


      </QuoteDesc>

      <h2>Studio for Mac/Windows</h2>
      <QuoteDesc>
        <a href="https://studio.diagram.codes">Diagram Codes Studio</a>
        &nbsp; is a powerful text-to-diagram software  for Mac and Windows platforms.


      </QuoteDesc>

      <Quote> Why? </Quote>
      <QuoteDesc>
        <p>
          You love diagrams but don't want to spend time positioning elements.
        </p>
        <p>
          Your diagram keeps evolving, you want the layout to re-adjust
          automatically.
        </p>
        <p>
          You prefer to describe your diagrams with an intuitive text
          description.
        </p>
      </QuoteDesc>

      <QuoteDesc>
        Twitter:{" "}
        <a className="link b ml2" href="https://twitter.com/DiagramCodes">
          @DiagramCodes
        </a>
      </QuoteDesc>

      <QuoteDesc>
        Questions or comments?
        <a className="link b ml2" href="https://contact.diagram.codes">
          Contact Us.
        </a>
      </QuoteDesc>
    </div>
  </div>
);

const diagrams = registry.getItems().map((i) => {
  return {
    name: i.name,
    path: `/d/${i.id}`,
    img: icons[i.id],
    id: i.id
  };
});

const Contents = (props) => (
  <div className="fl-ns w-100 w-60-m  w-60-l pb4">
    <div className="pa3">
      <Title level={2}>Please select the diagram type:</Title>
      <DiagramList diagrams={diagrams} />
      <div style={{ marginTop: "1rem" }}>
        More diagrams coming soon. Follow us on Twitter for updates
        <a className="link b ml2" href="https://twitter.com/DiagramCodes">
          @DiagramCodes.
        </a>
      </div>
    </div>
  </div>
);

export default () => {
  return (
    <div className="cf vh-100-ns overflow-auto-ns bg-white  avenir fl-ns w-100">
      <Intro />
      <Contents />
      <StatusBar />
    </div>
  );
};

export { Title };
