export default `
NO ARROWS

"Skeletal System" as skeletal
"Respiratory System" as respiratory
"Digestive System" as digestive
"Nervous System" as nervous
"Immune System" as immune

skeletal->muscular["supports"]
muscular->respiratory
respiratory->nervous["alerts"]
nervous->immune["activates"]
digestive->nervous
nervous->muscular
digestive->skeletal["nutrients"]

`