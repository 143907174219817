export default `
alias cr="Creative coding with JavaScript"
alias p5="p5.js"
alias render="Render Options"
alias tools="Tools"
alias t="Techniques"

cr->tools
cr->render
cr->t

tools->p5,"ShaderToy","Pts"

render->WebGL,SVG,Canvas
`