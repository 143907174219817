export default `
"START"
IF "is valid"
 "do something else"
 "and this"
 IF "Is it good?"
  "Send email"
 ELSE
  "Save record"
 END IF
ELSE
 "take another path"
`