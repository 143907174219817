import React,{useRef, useEffect} from 'react';
import AceEditor from 'react-ace';
import 'brace/mode/javascript'
import 'brace/theme/sqlserver'
import './editor-theme.css'
import syntaxRules from "@diagram_codes/editor-syntax-rules";
import diagramRegistry from '@hugo_volare/diagrams-layout-lib'
import createCustomEditor from './createCustomAceEditorMode';

/* Codigo setup ace tomado de:
   https://github.com/securingsincity/react-ace/issues/126#issuecomment-345151567
*/
const Editor =  ({value, diagramType,  onChange, className=""}) => {
    const ref = useRef();
    useEffect(()=>{
        const diagramEditorSyntaxRules = syntaxRules[diagramType]
        diagramEditorSyntaxRules && ref.current.editor.getSession().setMode(createCustomEditor(syntaxRules[diagramType]))

        //Para facilitar demos agregamos funcion que simule typing
        window.startDemo = (val)=>{
            setTimeout(()=>{
                let offset = 0;
                let pause = false;
                let i = setInterval(()=>{
                    if(pause){
                        pause = false;
                        return;
                    }
                    offset++;
                    if(offset > val.length){
                        clearInterval(i);
                        return;
                    }
                    if(val[offset] == '\n'){
                        pause = true;
                    }
                    let fragment = val.slice(0,offset);
                    onChange(fragment);
                },300);
            },3000);
        }


        //Editor font
        if(ref.current){
            ref.current.editor.setFontSize('1rem');
            ref.current.editor.setShowPrintMargin(false);
            /* Esto corrige un bug al hacer click sobre el editor con
               el split pane..
               TODO: cuando se cambie el tamano del panel hay que llamar resize de nuevo
            */
            setTimeout(()=>{
                ref.current.editor.resize()
            },300);
        }
    })

    return <AceEditor className={className} width="100%" height="100%" value={value} onChange={onChange} ref={ref} mode="text" theme="sqlserver"/>
}

export default Editor;