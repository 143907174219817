import React, { useEffect, useState } from "react";
import { SVGRenderer } from "@hugo_volare/diagrams-svg-renderer";
import diagramRegistry from "@hugo_volare/diagrams-layout-lib";
import styled from "styled-components";
import defaultTheme from "../editor/defaultTheme";

/* Esta pagina es usada por pupeteer para
   generar diagramas para el API.
   Agrega la funcion window.generateDiagram(type, code)
   Esta funcion renderiza el svg del diagrama para que 
   sea capturado.

   Se renderiza elemento con clase "ready" para que pupeteer
   tenga un mecanismo para saber que la funcion generadora
   esta lista.
*/
const Renderer = () => {
  const [diagramLayout, setDiagramLayout] = useState(null);
  const [error, setError] = useState(null);
  useEffect(() => {
    window.generateDiagram = (type, code) => {
      try {
        const layout = generateDiagram(type, code);
        setDiagramLayout(layout);
        setError(null);
      } catch (e) {
        setError(e);
        setDiagramLayout(null);
      }
    };
  }, []);

  return (
    <div>
      <span className="ready" />
      {error && <span className="error">{error.toString()}</span>}
      {diagramLayout && (
        <SVGRenderer zoom={1} layout={diagramLayout} theme={defaultTheme} />
      )}
    </div>
  );
};

/*
@type: id del diagrama
@code: codigo del diagrama
*/
function generateDiagram(type, code) {
  const module = diagramRegistry.getModule(type);
  if (!module) {
    throw new Error("Diagram type not supported:" + type);
  }

  //Crear modelo y compilarlo
  let model = new module.Model();
  let parser = module.parser;
  parser.yy.model = model;

  try {
    parser.parse(code);
    //Obtener diagramLayout
    return module.getLayout(model, defaultTheme);
  } catch (e) {
    const line = e.hash.line + 1;
    throw new Error("Diagram syntax error on line:" + line);
  } finally {
    parser.cleanupAfterParse();
  }
}

export default Renderer;
